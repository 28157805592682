 let _TDL = { 
	"event": [],
	"getEventData": () => { return _TDL.event; }
};

/**
 * Called by the Experimentation team to pass data to analytics for reporting.
 * @param {string} key - The key of the data to be sent, generally the target activityid.
 * @param {string} value - The value of the data to be sent.
 * example: sendTargetData("1234","success");
 */
const sendTargetData = ( key = "", value = "" ) => {

	//Exit early if no data passed in
	if ( !key || !value ) {

		return false;

	}	
	
	_TDL.event.push( `${key}:${value}` );

	return true

};

const init = () => {
	
	document.addEventListener( 'aape|pageviewsent', function ( event ) {
		console.log( LOG_PREFIX + " -- aape|pageviewsent: ", event );	
		
		const result = event.detail.data || _TDL.getEventData().join( "|" );

		if (!result) { return; }

		//Send data to analytics
		window.digitalData.event.push({
			"category": {
				"primaryCategory": "content display"
			},
			"eventInfo": {
				"eventName": "target data"
			},
			"data": result
		});

		if ( window._aape && _aape.PUB_SUB && typeof ( _aape.PUB_SUB.publish ) === "function" ) {

			_aape.PUB_SUB.publish( "thdcoreanalytics|logger", {
				"timestamp": Date(),
				"type": "info",
				"msg": "target Data layer TL call",
				"details": "",
				"tdl": result,
				"trigger": "aape|pageviewsent"
			} );

		}
		
	}, { once: true } );

    return _TDL;
}

export {	
    init,
	sendTargetData
};
